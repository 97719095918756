// Dependencies
import React, { useState, useEffect, useCallback, useRef } from "react";
// import { graphql } from "gatsby";
// import PropTypes from "prop-types";
// Hooks
// import useMouse from "../hooks/useMouse";
// Components
// import Image from "../components/Image";

import placeholder from "../images/placeholder.png";

function useImageLoad() {
  const imageRef = useRef();
  const [loaded, set] = useState(false);
  const handleLoad = () => {
    set(true)
  }
  useEffect(() => {
    if (imageRef.current && imageRef.current.complete) {
      set(true)
    }
  }, [])

  return [loaded, handleLoad, imageRef]
}

const Img = ({ className, fluid, fixed, file, style, setRef }) => {

  const [loaded, handleLoad, imageRef] = useImageLoad();
  const assignRefs = useCallback(node => {
    if (node) {
      if (setRef) {
        setRef.current = node
      }

      if (imageRef) {
        imageRef.current = node
      }
    }
  }, [])

  const common = {
    onLoad: handleLoad,
    ref: assignRefs,
    loading: "lazy",
    style: {
      opacity: loaded ? 1 : 0,
      transition: 'opacity 0.5s',
      ...style
    }
  }

  return fixed ? (
    <img className={className} src={fixed.src} srcSet={fixed.srcSet} sizes={fixed.sizes} {...common} />
  ) : fluid ? (
    <img className={className} src={fluid.src} srcSet={fluid.srcSet} sizes={fluid.sizes} {...common} />
  ) : (
    <img className={className} src={file && file.url || placeholder} {...common} />
  )
}

export default Img
