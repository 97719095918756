// Dependencies
import React, { useState, useEffect, useRef, useCallback } from "react";
// import { graphql } from "gatsby";
// import PropTypes from "prop-types";
// Hooks
import useMedia from "../hooks/useMedia";
import useMount from "../hooks/useMount";
// Components
import ComposerInfo from "../components/ComposerInfo";
import ComposerName from "../components/ComposerName";
import { CloseIcon } from "../components/SVGs";

function useFadeUnderHeader(ref) {

  const [isTop, set] = useState(false);

  const handleScroll = () => {

    const { top } = ref.current.getBoundingClientRect();
    const dist = document.getElementById("header").offsetHeight;

    if (top < (dist - 20)) {
      set(true)
    } else {
      set(false)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, false);
    handleScroll();
    return () => {
      window.removeEventListener('scroll', handleScroll, false);
    }
  }, [])

  return isTop
}

const Names = (props) => (
  props.names.map((item, col) => (
    <ComposerName
      composer={item}
      handleNameClick={props.handleNameClick}
      activeComposer={props.activeComposer}
      loop={props.loop}
      key={`${props.setKey}-${props.offset}-composer-${col}`}
    />
  ))
)

function useClickOutside(active, callback) {
	const el = useRef();
	
	const ref = useCallback(node => {
    if (node && active) {
			document.addEventListener("mousedown", handleClick);
			el.current = node;
    } else {
      document.removeEventListener("mousedown", handleClick);
    }
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, [active]);

  const handleClick = e => {
    if (el.current && el.current.contains(e.target)) {
			// inside click
      return;
    }
		// outside click
    callback();
  };

  return ref
}

const Marquee = (props) => {

  const [activeComposer, setActiveComposer] = useState(null);
  const [showInfo, setInfo] = useState(false);
	const [x, setXPosition] = useState(0);
	const reveal = useMount();
  const wrapper = useRef();
  const contents = useRef();
  // const speed = useRef(5);
  const velocity = useRef(0);
  const animator = useRef();
  const timer = useRef();

  const mediaQuery = useMedia(
    // Media queries
    [
			'(pointer: coarse) and (max-width: 768px) and (orientation: portrait)', 
			'(pointer: coarse) and (min-width: 768px) and (orientation: landscape)',
			'(pointer: fine) and (orientation: landscape)',
		],
    // Column counts (relates to above media queries by array index)
    ['is-mobile', 'is-tablet', 'is-desktop'],
    // Default column count
    'is-desktop'
	);
	const isMobile = mediaQuery === 'is-mobile';

  const isTop = useFadeUnderHeader(wrapper);

  const move = () => {
    animator.current = window.requestAnimationFrame(move)

    if (contents.current && !activeComposer) {
      setXPosition(prev => {
        if (prev >= contents.current.offsetWidth * 2) {
          // Position of checkpoint...
          return contents.current.offsetWidth;
        } if (prev <= 0) {
          // Position of checkpoint...
          return contents.current.offsetWidth;
        } else {
          // Current x position -/+ the velocity
          return prev + velocity.current;
        }
      })
    }
  }

  const handleMouseEnter = () => {
		window.cancelAnimationFrame(timer.current);
    window.cancelAnimationFrame(animator.current);
    animator.current = window.requestAnimationFrame(move);
    props.setFocus(props.offset);
	}
	
	const handleTouchStart = () => {
		window.cancelAnimationFrame(timer.current);
		window.cancelAnimationFrame(animator.current);
		velocity.current = 1;
    animator.current = window.requestAnimationFrame(move);
    props.setFocus(props.offset);
  }

  const handleMouseMove = ({ clientX }) => {
    const x = ((clientX / window.innerWidth) * 2) - 1;

    // Adjust velocity
    const adjust = Math.floor(5 * x);
    // Only set at outer edges...
    velocity.current = ((adjust > 2) || (adjust < -2)) ? adjust : 0;
  }

  const handleMouseLeave = ({ clientX }) => {

    let x = ((clientX / window.innerWidth) * 2) - 1;

    const decelerate = () => {
			
      timer.current = window.requestAnimationFrame(decelerate);

      if (x < -0.05) {
        x = x + 0.01;
      } else if (x > 0.05) {
        x = x - 0.01;
      } else {
        window.cancelAnimationFrame(timer.current);
        window.cancelAnimationFrame(animator.current);
      }

      velocity.current = Math.floor(5 * x);
    }

    timer.current = window.requestAnimationFrame(decelerate);
  }

  // CLEANUP: When unmounting, clear the animation frames
  useEffect(() => {
    if (isMobile) {
      animator.current = window.requestAnimationFrame(move);
      velocity.current = props.offset % 2 < 1 ? -0.5 : 0.5;
    }

    return () => {
      window.cancelAnimationFrame(timer.current);
      window.cancelAnimationFrame(animator.current);
    }
  }, [isMobile])

  const handleNameClick = (content, element) => {

		const { top } = element.getBoundingClientRect();
    const scrollPosition = window.scrollY || document.body.scrollTop || document.documentElement.scrollTop;
    const headerHeight = document.getElementById("header").offsetHeight;

    if (props.showArtistView) {
			closeArtistView();
    } else {
			document.documentElement.style.scrollBehavior = "smooth";
      window.scrollTo(0, scrollPosition + top - headerHeight);
      setActiveComposer(content);
      props.setArtistView(true);
    }

    // Stop animation (if desktop)
    if (!isMobile) {
			velocity.current = 0;
      setXPosition(prev => {
				// 0.022
				// 0.008250825
				// 0.00752394
				const newPosition = prev - (x - element.offsetLeft) + (window.innerWidth * 0.008250825);
        return newPosition
      })
      window.cancelAnimationFrame(timer.current);
      window.cancelAnimationFrame(animator.current);
    }
	}

  const handleClose = () => {
    setInfo(false)
  }

  const closeArtistView = () => {
    setActiveComposer(null);
    props.setArtistView(false);
		move();
		
		//
		document.documentElement.style.scrollBehavior = "auto";
  }

  // When clicking outside info...
  const infoRef = useClickOutside(activeComposer, () => {
    handleClose()
  });

  // Mouse handlers
  const handlers = !isMobile && !activeComposer ? {
    onMouseEnter: handleMouseEnter,
    onTouchStart: handleTouchStart,
		onMouseLeave: handleMouseLeave,
		onTouchEnd: handleMouseLeave,
		onMouseMove: handleMouseMove,
  } : {}

  // OPACITY BASED ON DISTANCE FROM ACTIVE ROLLOVER
  const opacityCalc = ((props.offset - props.focus) * 0.14);
  const opacityHi = 0.5;
  const opacityLo = 0.1;
  const css = !reveal ? {
		opacity: 0,
	} : props.showArtistView ? {
    // Artist view is open...
    opacity: 1
  } : props.offset < props.focus ? {
    // Is Behind...
    opacity: (opacityHi + opacityCalc) > opacityLo ? (opacityHi + opacityCalc) : opacityLo
  } : props.offset > props.focus ? {
    // Is Ahead...
    opacity: (opacityHi - opacityCalc) > opacityLo ? (opacityHi - opacityCalc) : opacityLo
	} : {}
	
  return (
    <div
      className={`marquee hide-scrollbars ${activeComposer ? 'active' : props.showArtistView ? 'artist-view-open' : 'inactive'} ${props.debug ? '' : ''} ${isTop ? 'past-header' : ''}`}
      {...handlers}
      style={{
        transition: `opacity 0.6s`,
        ...css
      }}
    >
      <div className="wrapper nowrap" ref={wrapper} style={{ transform: `translateX(-${x}px)` }}>
        <div className="nowrap" ref={contents}>
          <Names {...props} handleNameClick={handleNameClick} activeComposer={activeComposer} setKey={props.setKey} />
        </div>
        <div className="nowrap">
          <Names {...props} handleNameClick={handleNameClick} activeComposer={activeComposer} setKey={props.setKey} />
        </div>
        <div className="nowrap">
          <Names {...props} handleNameClick={handleNameClick} activeComposer={activeComposer} setKey={props.setKey} />
        </div>
      </div>

      {activeComposer && (
        <div className="prel" style={{ marginTop: '8px' }} ref={infoRef}>
          <div className="composer__close color-rollover" onClick={() => setInfo(false)}>
            <CloseIcon />
          </div>
          <ComposerInfo {...activeComposer} showInfo={showInfo} setInfo={setInfo} closeArtistView={closeArtistView} contactEmail={props.contactEmail} />
        </div>
      )}
    </div>
  )
}

export default Marquee
