// Dependencies
import React, { useState, useEffect, useRef, useCallback } from "react";
import lottie from "lottie-web";
// import { graphql } from "gatsby";
// import PropTypes from "prop-types";
// Hooks
// import useMouse from "../hooks/useMouse";
// Components
// import Image from "../components/Image";

const AnimatedLogo = ({ dataPath, onComplete, onAnimationEnd, handleAnimationEnd }) => {

  const animation = useRef();

  const init = useCallback(node => {
    if (node) {
      animation.current = lottie.loadAnimation({
        container: node,
        renderer: 'svg',
        loop: false,
        autoplay: false,
        path: dataPath
      });
      animation.current.addEventListener('complete', () => {
        handleAnimationEnd();
      })
    }
  }, [])

  useEffect(() => {
    // After delay, play
    const timer = setTimeout(() => {
      animation.current.play()
    }, 2000)

    return () => {
      clearTimeout(timer)
    }
  }, [])

  return (
    <div className={`intro__logo`} onAnimationEnd={handleAnimationEnd} ref={init} />
  )
}

export default AnimatedLogo
