// Dependencies
import React, { useState, useEffect, useCallback } from "react";
// Hooks
import useMedia from "../../hooks/useMedia";
// Components
import AnimatedLogo from "../../components/AnimatedLogo";
import { RepresentsLogo } from "../../components/SVGs";

const BackgroundVideo = (props) => {

  const src = props.videoUpload ? props.videoUpload.file.url : props.videoSourceUrl;
  const placeholder = props.placeholderImage ? props.placeholderImage.file.url : '';

  const [showPlaceholder, setPlaceholder] = useState(false);
  const [canPlay, setPlay] = useState(false);

  const init = useCallback(node => {
    if (node) {
      node.play().then(() => {
        setPlay(true)
      }).catch(error => {
        setPlaceholder(true);
      })
    }
  })

  return !showPlaceholder ? (
    <video className="background-video" src={src} ref={init} controls={false} loop playsInline muted style={{
      opacity: canPlay ? 1 : 0
    }} />
  ) : (
    <img src={placeholder} className="background-video" />
  )
}

const Intro = ({ showIntro, setShowIntro, setHideHeader, videoClips, hasHash }) => {

  const [step, set] = useState(0); // 0
  const [finished, setFinished] = useState(false);

  const handleAnimationEnd = () => {
		set(n => n + 1);
		// set(1)
  }

  const handleIntroEnd = () => {
    setFinished(true)
  }

  const handleClick = () => {
    setShowIntro(false)
  }

  useEffect(() => {
    if (step >= 2) {
      setHideHeader(false)
    }
	}, [step])

	useEffect(() => {
		let timer;
		if (hasHash) {
			timer = setTimeout(() => {
				setShowIntro(false)
			}, 1200)
		}
		return () => {
			clearTimeout(timer)
		}
	}, [hasHash])

	const isMobile = useMedia(
    // Media queries
    ['(min-width: 768px)'],
    // Column counts (relates to above media queries by array index)
    [false],
    // Default column count
    true
	);

  return (
    !finished && (
      <div className={`intro--represents ${!showIntro ? 'finish' : ''}`} onTransitionEnd={handleIntroEnd} onClick={handleClick}>
        {videoClips && <BackgroundVideo {...videoClips[0]} />}

        {step === 0 && (
          <AnimatedLogo dataPath={`/logo-animation-v3.json`} handleAnimationEnd={handleAnimationEnd} />
        )}
				{/* <AnimatedLogo handleAnimationEnd={handleAnimationEnd} /> */}

        {step > 0 && step < 3 && (
          <RepresentsLogo className={`intro__represents`} onAnimationEnd={handleAnimationEnd} />
        )}
				{/* <RepresentsLogo className={`intro__represents`} /> */}

        {step >= 2 && (
          <div className="intro__tagline" onAnimationEnd={handleAnimationEnd}>
            <div>
              {isMobile ? (
                <span className="h1 mr">
                  Boosey & Hawkes represents the best classical music copy rights with exclusive masters
                </span>
              ) : (
                <span className="h1 mr">
									<span className="spacing">the best j</span>
                  Boosey & Hawkes represents <br /> the best classical music copy rights with <br />exclusive masters
                </span>
              )}

              <span className="p1 intro__cta">
                Meet the <br />composers →
              </span>
            </div>
          </div>
        )}
      </div>
    )
  )
}

export default Intro
