// Dependencies
import React, { useRef, useState, useEffect } from "react";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
// Hooks
import useMedia from "../hooks/useMedia";
// Components
import Composers from "../components/Composers";
import Info from "../components/Info";
import Intro from "../components/represents/Intro";
import Layout from "../components/Layout";

function useComposers(array) {
	const rowLength = 3;
	const grid = [...Array(20).keys()].map(n => [...Array(rowLength).keys()]);

	const populate = useRef(
		grid.map((row, rowIndex) => {
			return row.map((col, colIndex) => {
				const t = array.length;
				const n = rowIndex * rowLength + colIndex;
				return array[n % t];
			})
		})
	)

	return populate.current;
}

function useOneComposerPerRow(array) {
  const copy = [...array];
  const rows = useRef(
    copy.map((item, index) => [item, item, item])
  )
  return rows.current
}

const HomepageTemplate = ({ data, location, pageContext }) => {

	// Content
	const { composers, color, backgroundColor } = data.contentfulHomepage;
  const desktopRows = useComposers(composers);
	const mobileRows = useOneComposerPerRow(composers);
	
	// UX
  const [showIntro, setShowIntro] = useState(true);
  const [hideHeader, setHideHeader] = useState(true);
	const [showArtistView, setArtistView] = useState(false);

	useEffect(() => {
		if (!pageContext.showIntro) {
			setHideHeader(false);
			setShowIntro(false);
		}
	}, [pageContext.showIntro])
	
	// Styles
  const isMobile = useMedia(
    // Media queries
    ['(min-width: 768px) and (orientation: landscape)'],
    // Column counts (relates to above media queries by array index)
    [false],
    // Default column count
    true
	);

	const theme = color && backgroundColor && `
		:root {
			--background: ${backgroundColor};
			--color: ${color};
		}
	`;

  return (
    <Layout showIntro={showIntro} setShowIntro={setShowIntro} hideHeader={hideHeader} location={location} css={theme}>
			{pageContext.showIntro && (
				<Intro
					setHideHeader={setHideHeader}
					showIntro={showIntro}
					setShowIntro={setShowIntro}
					videoClips={data.contentfulHomepage.introVideoClips}
					isMobile={isMobile}
					hasHash={location.hash ? true : false}
				/>
			)}

			{!isMobile ? (
				<Composers composers={desktopRows} showArtistView={showArtistView} setArtistView={setArtistView} repeat={3} setKey={`is-desktop`} key={`is-desktop`} />
			) : (
				<Composers composers={mobileRows} showArtistView={showArtistView} setArtistView={setArtistView} setKey={`is-mobile`} key={`is-mobile`} />
			)}

      <Info {...data.contentfulHomepage} />
    </Layout>
  )
}

HomepageTemplate.propTypes = {
  data: PropTypes.object.isRequired
}

export default HomepageTemplate

export const HomepageTemplateQuery = graphql`
  query HomepageTemplateQuery($slug: String!) {
    contentfulHomepage(slug: { eq: $slug }) {
			## Landing page
      introVideoClips {
				videoUpload {
					file {
						url
					}
				}
				placeholderImage {
					file {
						url
					}
				}
			}
			## Info page stuff
			infoTitle
			infoLongTitle {
				childMarkdownRemark {
					html
				}
			}
			infoAbout {
				childMarkdownRemark {
					html
				}
			}
			infoContactTitle
			infoContactCopy {
				childMarkdownRemark {
					html
				}
			}
			infoAsideCopy {
				childMarkdownRemark {
					html
				}
			}
			## Theme
			color
			backgroundColor
			## Composers
			composers {
				name
				portrait {
					fluid(maxWidth: 800) {
						...GatsbyContentfulFluid_noBase64
					}
					fixed(width: 800, height: 800) {
						...GatsbyContentfulFixed
					}
				}
				about {
					raw
				}
				links {
					label
					title
					titleMultipleLine {
						childMarkdownRemark {
							html
						}
					}
					url
				}
			}
    }
  }
`