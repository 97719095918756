// Dependencies
import React, { useRef, useState, useEffect } from "react";
// import { useInView } from 'react-intersection-observer';
// import { graphql } from "gatsby";
// import PropTypes from "prop-types";
// Hooks
import useSettings from "../hooks/useSettings";
// Components
import { BooseyHawkesEdit, RepresentsLogo, LibraryLogo, MailIcon } from "../components/SVGs";
import Markdown from "../components/Markdown";

function useInView() {
	const ref = useRef();
	const [inView, set] = useState(false);

	useEffect(() => {
		handleScroll();
		window.addEventListener('scroll', handleScroll);
		return () => {
			window.removeEventListener('scroll', handleScroll, false);
		}
	}, [])

	const handleScroll = () => {
		if (ref.current) {
			const { top } = ref.current.getBoundingClientRect();
			if (top < (window.innerHeight * 0.7)) {
				set(true);
			} else {
				set(false);
			}
		}
	}

	return [ref, inView]
}

const	HideUnderHeader = ({ className, children, debug, isLibrary }) => {

	const ref = useRef();
	const [hide, set] = useState(false);

	useEffect(() => {
		handleScroll();
		window.addEventListener('scroll', handleScroll);
		return () => {
			window.removeEventListener('scroll', handleScroll, false);
		}
	}, [])

	const handleScroll = () => {
		if (ref.current) {
			const { top } = ref.current.getBoundingClientRect();
			const headerHeight = document.getElementById("header").offsetHeight;
			const adjust = isLibrary ? window.innerWidth < 768 ? 1.1 : 0.66 : 0.66; // yuck
			
			if (debug) {
				console.log(top);
			}
			
			if (top < (headerHeight * adjust)) {
				set(true);
			} else {
				set(false);
			}
		}
	}

	return (
		<div className={className} ref={ref} style={{
			opacity: !hide ? 1 : 0.1,
			transition: `0.3s`
		}}>
			{children}
		</div>
	)
}

const Info = (props) => {

	const { 
		infoTitle, 
		infoAbout, 
		infoContactTitle, 
		infoBooseyCopyLink, 
		infoLibraryCopyLink, 
		contactEmail 
	} = useSettings();

  const [ref, inView] = useInView();

  return (
    <section id="info" className="info-page padx2" ref={ref} style={{
      opacity: inView ? 1 : 0,
      transition: 'opacity 0.8s'
    }}>
			<HideUnderHeader isLibrary={props.isLibrary}>
				<Markdown className="h1 info-title" field={props.infoLongTitle} />
			</HideUnderHeader>
      
      <HideUnderHeader className="grid-5up outerx4" isLibrary={props.isLibrary}>
        <Markdown className="span-3 p1" field={props.infoAbout || infoAbout} />
        <div className=""></div>
        <div className="info-page-contact">
          <a href={`mailto:${contactEmail}`} className="color-rollover">
            <h2 className="mb">{props.infoContactTitle || infoContactTitle}</h2>
            <MailIcon />
          </a>
        </div>
      </HideUnderHeader>

      <HideUnderHeader className="grid-5up" isLibrary={props.isLibrary}>
        <div className="span-3 bdr-top--thick">
          <div className="info-page-logo">
            <BooseyHawkesEdit />
            <Markdown className="outerx2 has-links p2" field={props.infoContactCopy || infoBooseyCopyLink} />
          </div>
        </div>
        <div className=""></div>
        {/* <div className="bdr-top--thick">
          <div className="info-page-logo">
						{props.isLibrary ? <RepresentsLogo /> : <LibraryLogo />}
            
            <Markdown className="outerx2 has-links p2" field={props.infoAsideCopy || infoLibraryCopyLink} />
          </div>
        </div> */}
      </HideUnderHeader>
    </section>
  )
}

export default Info
