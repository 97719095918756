// Dependencies
import React, { useEffect, useState, useRef } from "react";
// Hooks
import useMount from "../hooks/useMount";
// Components
import RichText from "../components/RichText";
import Img from "../components/Img";
import Markdown from "../components/Markdown";
import { MailIcon } from "../components/SVGs";

const TabLink = ({ url, children, onTransitionEnd, style }) => url ? (
  <a href={url} className="tab is-link" style={style} onTransitionEnd={onTransitionEnd}>{children}</a>
) : (
  <div className="tab" style={style} onTransitionEnd={onTransitionEnd}>{children}</div>
)

const Tab = ({ title, titleMultipleLine, label, url, index, isLast }) => {

	const show = useMount();
	const [finished, setFinished] = useState(false);

	const handleTransitionEnd = () => {
		setFinished(true)
	};

  return (
    <TabLink url={url} onTransitionEnd={handleTransitionEnd} style={!finished ? {
			opacity: show ? 1 : 0,
			transition: `1s`,
			transitionDelay: `${index * 0.3}s`
		} : {}}>
      <h5>{label}</h5>
      {titleMultipleLine ? (
        <Markdown className="h3" field={titleMultipleLine} />
      ) : (
        <h3>{title}</h3>
      )}
    </TabLink>
  )
}

const ComposerInfo = ({ name, about, links, portrait, showInfo, setInfo, closeArtistView, contactEmail }) => {

  const contents = useRef();
  const [height, set] = useState(0);

  useEffect(() => {
    setInfo(true)
  }, [])

  useEffect(() => {
    if (showInfo) {
      if (contents.current) {
        set(contents.current.offsetHeight)
      }
    } else {
      set(0)
    }
  }, [showInfo])

  const handleEnd = () => {
    if (!showInfo) {
      closeArtistView()
    }
	}

	// Lock scroll when open...
	useEffect(() => {
		if (showInfo) {
			document.documentElement.style.overflow = "hidden";
		} else {
			document.documentElement.style.overflow = null;
		}
	}, [showInfo])

  return (
    <div className={`prel composer__info ${showInfo ? 'show' : ''}`} style={{
      height: height
    }} onTransitionEnd={handleEnd}>
      <div className="guttersx2" ref={contents}>
        <div className="bdr-top grid-7up has-gaps">
          <div className="span-5">
            <RichText content={about} />
          </div>
          <div className="composer__portrait">
            <Img {...portrait} />
          </div>
          <div className="p1">
            <a href={`mailto:${contactEmail}`} className="get-in-touch color-rollover">
              <div className="label">Get in touch <br />with our global <br /> sync team</div>
              <MailIcon />
            </a>
          </div>
        </div>
        <div className="composer__links innerx2">

          {/* {links && links.length && (
            <div className="tab">
              <h5>{" "}</h5>
              <h3>Search by →</h3>
            </div>
          )} */}

          {links && links.map((item, index) => (
            <Tab {...item} index={index} isLast={index === links.length - 1} key={`tab-${index}`} />
          ))}
        </div>
      </div>
    </div>
  )
}

export default ComposerInfo
