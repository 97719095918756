// Dependencies
import React, { useState } from "react";
// Hooks
import useSettings from "../hooks/useSettings";
// Components
import Marquee from "../components/Marquee";

const Composers = ({ composers, showArtistView, setArtistView, setKey }) => {

  const { contactEmail } = useSettings();
	const [focus, setFocus] = useState(-1);

  return (
    <div className="composers">
      {composers && composers.map((names, index) => {
        return (
          <Marquee
            names={names}
            offset={index}
            debug={index === 4}
            showArtistView={showArtistView}
            setArtistView={setArtistView}
            focus={focus}
            setFocus={setFocus}
            contactEmail={contactEmail}
						setKey={`${setKey}-marquee-${index}`}
            key={`${setKey}-marquee-${index}`}
          />
        )
      })}
    </div>
  )
}

export default Composers
