// Dependencies
import React, { useRef, useState, useCallback } from "react";
// Hooks
import useMount from "../hooks/useMount";
// Components
import Img from "../components/Img";

const ComposerName = ({ composer, handleNameClick, activeComposer }) => {

	const ref = useRef();
	const imageSource = useCallback(() => {
		return composer.portrait
	}, [composer]);
	const ready = useMount();
  const [highlight, setHighlight] = useState(false);
  const isActive = activeComposer && activeComposer.name === composer.name;

  const handleClick = () => {
    handleNameClick(composer, ref.current)
    setHighlight(true);
  }

  return (
    <div className={`h1 composer__name ${isActive && highlight ? 'active' : ''}`} ref={ref} onClick={handleClick}>
      <span className="name">{composer.name}</span>
			{ready && <Img className="thumbnail" {...imageSource()} style={{ transition: '0.5s' }} />}
    </div>
  )
}

export default ComposerName
